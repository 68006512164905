import {
  type Address,
  type AxiosRequestConfig,
  epApiUrl,
  epHttpClient,
  type Feature,
  formatFeatureToAddress,
} from '@ahm/api-wrappers-core';
import { apiRoutes } from '../api-routes';
import { publicEnv } from '@/config/public-env.config.mjs';

export interface AutocompleteData {
  features: Feature[];
  source_list?: string[];
  type?: string;
}

export type AutocompleteParams = {
  input: string;
  lat?: number;
  lng?: number;
  mode?: string;
};

type Params = {
  text: string;
  'focus.point.lat'?: number;
  'focus.point.lng'?: number;
};

export const getAutocompleteFn = async (
  requestParams: AutocompleteParams,
  config: AxiosRequestConfig = {}
) => {
  const { input: text, lat, lng } = requestParams;
  const url = apiRoutes.place.autocomplete;

  const params: Params = {
    text,
  };

  if (lat && lng) {
    params['focus.point.lat'] = lat;
    params['focus.point.lng'] = lng;
  }

  const response = await epHttpClient({
    apiURL: publicEnv.NEXT_PUBLIC_EP_API_URI,
    apiKey: publicEnv.NEXT_PUBLIC_EP_API_KEY,
  }).get<AutocompleteData>(epApiUrl + url, {
    params,
    ...config,
  });

  const data = response.data;

  const predictions: Address[] = [];
  if (data?.features) {
    data.features.forEach((feature) => {
      if (Array.isArray(feature)) {
        feature.forEach((item) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const address = formatFeatureToAddress(item);
          if (address) predictions.push(address);
        });
      } else {
        const address = formatFeatureToAddress(feature);
        if (address) predictions.push(address);
      }
    });
  }
  return predictions;
};
