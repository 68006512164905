import {
  type AxiosRequestConfig,
  epHttpClient,
  googleAddressResponseToAddress,
} from '@ahm/api-wrappers-core';
import { apiRoutes } from '../api-routes';
import { publicEnv } from '@/config/public-env.config.mjs';

export interface GoogleAddressResponse {
  address_components: AddressComponent[];
  formatted_address: string;
  geometry: GeometryDetail;
  place_id: string;
  plus_code: PlusCode;
  types: string[];
  sessiontoken: string;
  fromCache: boolean;
  name: string;
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GeometryDetail {
  location: {
    lat: number;
    lng: number;
  };
  location_type: string;
  viewport: Viewport;
}
export interface Viewport {
  northeast: Location;
  southwest: Location;
}
export interface PlusCode {
  compound_code: string;
  global_code: string;
}

export interface GoogleAddressParams {
  place_id: string;
  sessiontoken?: string;
}

export const getGoogleAddressFn = async (
  params: GoogleAddressParams,
  config: AxiosRequestConfig = {}
) => {
  const response = await epHttpClient({
    apiURL: publicEnv.NEXT_PUBLIC_EP_API_URI,
    apiKey: publicEnv.NEXT_PUBLIC_EP_API_KEY,
  }).get<GoogleAddressResponse>(apiRoutes.place.detail, {
    params,
    ...config,
  });
  return googleAddressResponseToAddress(response.data);
};
